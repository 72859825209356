import React from "react";
import Icon from '@Icons/apple-store.svg';

export default function AppleStore() {
    return (
        <>
            <a href="https://apps.apple.com/pl/app/nexity/id1457564789?l=pl" target="_blank">
                <Icon />
            </a>
        </>
    )
}