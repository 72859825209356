import React from "react";
import Icon from '@Icons/play-store.svg';

export default function PlayStore() {
    return (
        <>
            <a href="https://play.google.com/store/apps/details?id=pl.nexity.echarge" target="_blank">
                <Icon />
            </a>
        </>
    )
}